import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import welcomeStyles from './welcome.module.css'

import Img from 'gatsby-image'

export default () => {
  const heroPic = useStaticQuery(graphql`
    query getHero {
      file(name: {eq: "Jonatan"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={welcomeStyles.welcomeWrapper}>
      <h1 className={welcomeStyles.heading}>Welcome!</h1>
      <p className={welcomeStyles.p}>Hi! Thanks for visiting my site. As a professional photographer for the past 12 years I’ve served couples during some of the most important moments of their lives. </p>
      <Img fluid={heroPic.file.childImageSharp.fluid} style={{ height: `40vh`, margin: `auto` }} imgStyle={{ objectPosition: `center 20%` }} />
      <p className={welcomeStyles.p}>I’m committed to offering you excellence and quality throughout your experience by capturing images that will showcase your greatest feelings and expressions. </p>
      <p className={welcomeStyles.p}>Take a look at my portfolio by clicking on Weddings below. If you like what you see, please reach out to me. I'd love to partner with you and help you capture your special day!</p>
    </div>
)}