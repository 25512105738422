import React from 'react'

export default props => (
  <svg className={props.iconClass} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 883 768">
    <g>
      <g>
        <path d="M483.5,401.4c-13.9,30.8-34,58.9-58.5,81.9s-53.4,40.9-84.5,52.6c-31,11.7-64.2,17.2-97.1,16.1
          c-32.9-1-65.3-8.7-94.8-22.1c-29.5-13.4-56.3-32.5-78.3-56s-39.1-51.1-50.2-80.8C9,363.4,3.7,331.7,4.8,300.3
          c1-31.4,8.4-62.4,21.2-90.5c12.8-28.2,31.1-53.7,53.6-74.6c22.4-20.9,48.8-37.2,77.2-47.8c28.3-10.6,58.6-15.5,88.5-14.5
          s59.4,8.1,86.2,20.3c26.8,12.3,51.1,29.7,71,51.1s35.3,46.5,45.4,73.5c10,27,14.7,55.8,13.6,84.2c-1,28.4-7.8,56.4-19.5,81.9
          L483.5,401.4z M442,384c10-26.2,14.7-54.3,13.8-81.9c-0.8-27.7-7.2-55-18.4-79.9c-11.2-24.9-27.3-47.4-46.9-65.9
          s-42.8-32.8-67.7-42.3c-24.9-9.4-51.4-13.8-77.6-13s-52,6.9-75.6,17.6s-44.8,25.9-62.2,44.5s-30.9,40.5-39.8,64.1
          c-8.8,23.6-13,48.6-12.1,73.3c0.8,24.7,6.6,49.1,16.7,71.3c10.1,22.2,24.5,42.2,42.1,58.6c17.5,16.4,38.2,29,60.4,37.3
          c22.3,8.3,45.8,12.1,69,11.2c23.2-0.8,46.1-6.3,67-15.9s39.6-23.2,54.9-39.7s27.1-35.9,34.9-56.8L442,384z"/>
      </g>
      <g>
        <path d="M400.9,366.7c13.9-30.8,34-58.9,58.5-81.9s53.4-40.9,84.5-52.6c31-11.7,64.2-17.2,97.1-16.1
          c32.9,1,65.3,8.7,94.8,22.1c29.5,13.4,56.3,32.5,78.3,56s39.1,51.1,50.2,80.8c11.2,29.7,16.4,61.4,15.3,92.8
          c-1,31.4-8.4,62.4-21.2,90.5c-12.8,28.2-31.1,53.7-53.6,74.6c-22.4,20.9-48.8,37.2-77.2,47.8c-28.3,10.6-58.6,15.5-88.5,14.5
          s-59.4-8.1-86.2-20.3c-26.8-12.3-51.1-29.7-71-51.1c-19.9-21.4-35.3-46.5-45.4-73.5c-10-27-14.7-55.8-13.6-84.2
          c1-28.4,7.8-56.4,19.5-81.9L400.9,366.7z M442.4,384c-10,26.2-14.7,54.3-13.8,81.9c0.8,27.7,7.2,55,18.4,79.9
          c11.2,24.9,27.3,47.4,46.9,65.9s42.8,32.8,67.7,42.3c24.9,9.4,51.4,13.8,77.6,13c26.2-0.8,52-6.9,75.6-17.6
          c23.6-10.7,44.8-25.9,62.2-44.5s30.9-40.5,39.8-64.1c8.8-23.6,13-48.6,12.1-73.3c-0.8-24.7-6.6-49.1-16.7-71.3
          c-10.1-22.2-24.5-42.2-42.1-58.6c-17.5-16.4-38.2-29-60.4-37.3c-22.3-8.3-45.8-12.1-69-11.2c-23.2,0.8-46.1,6.3-67,15.9
          s-39.6,23.2-54.9,39.7c-15.3,16.5-27.1,35.9-34.9,56.8L442.4,384z"/>
      </g>
    </g>
  </svg>
)