import React from 'react'

export default props => (
  <svg version="1.1" className={props.iconClass} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 380 380">
    <g>
      <path d="M137.4,24.3c-5.7,15.5-11.4,31-17.3,47c-1.2,1-2.7,2.3-4.3,3.6C92.2,93.4,72.6,115.2,59,142.2
        c-5.9,11.7-10.2,24.1-12.9,36.9c-1.3,6.3-1.7,12.8-2.4,19.2c-0.4,3.3-0.8,6.6-0.8,9.9c-0.1,32.1,10.5,60.5,29.7,85.8
        c2.6,3.4,5.9,6.3,9,9.3c1.1,1,2.4,1.9,4.3,0.8c1.9-2.7,1.7-6,1.6-9.3c-0.1-5.3-0.1-10.6-0.1-15.9c0-1.8,0.2-3.7,0.2-5.5
        c0.1-10.5,1.6-20.8,2.9-31.2c3.5-28,9.9-55.3,19-82c9.3-27.5,21.5-53.6,37.2-78c7.8-12.2,16.3-23.9,25.8-34.8
        c5.3-6,10.6-11.9,16-17.7c4.8-5.1,10.4-9.3,16.4-13c6.8-4.2,14.3-5.1,22.1-4.7c4.8,0.3,9.6,1.1,14.3,1.6
        c14.3,1.5,27.9,5.4,41.2,10.6c15.7,6.2,30.2,14.6,43.3,25.3c21.2,17.4,36.6,38.8,44.9,65.1c2.9,9,5.1,18.2,6.8,27.5
        c0.7,4.2,0.9,8.6,1.3,12.9c0.4,4.5,1,9,0.8,13.4c-0.3,8-1,15.9-1.8,23.8c-2.2,22.2-8.9,43-18.9,62.9c-6.9,13.7-15.2,26.4-25.1,38.2
        c-8.7,10.4-18.2,19.9-28.7,28.3c-17.5,14-36.7,25.1-57.6,33.3c-1.5,0.6-2.9,1.2-5.1,2c1.8-5.1,3.4-9.4,4.9-13.7
        c3.8-10.4,7.6-20.8,11.3-31.3c0.9-2.4,2.2-4.4,4.3-5.9c11.9-8.6,22.5-18.8,32.3-29.7c13.4-14.9,24-31.8,31.3-50.6
        c4.3-11,7.4-22.4,8.8-34c1.2-10,1.6-20.1,0.7-30.3c-1.3-13.6-4.1-26.8-8.7-39.6c-5-13.9-12.1-26.6-21.3-38
        c-2.9-3.6-5.7-7.5-10.9-8.8c-0.6,0.5-1.4,1.1-2.5,1.9c0,2.2,0,4.5,0,6.8c0,5.8,0.2,11.7,0,17.5c-0.4,8.3-1,16.6-1.8,24.8
        c-0.9,8.6-1.8,17.1-3.2,25.6c-1.4,8.6-3.1,17.3-5.2,25.7c-3.3,13.1-6.8,26.2-10.9,39.1c-4.4,13.6-9.6,26.8-15.5,39.8
        c-7.7,17-16.3,33.4-26.9,48.8c-7.3,10.7-15.3,20.9-24.4,30.2c-5.3,5.3-10,11.1-15.4,16.3c-7.7,7.5-16.5,13.5-27.3,15.4
        c-4.4,0.7-9,1-13.4,0.4c-13.7-2-27.4-4.5-40.7-8.8c-16.2-5.2-31.6-12-45.7-21.7c-22.1-15.2-38.1-35.2-48.5-59.9
        c-5.2-12.4-8.5-25.4-10.8-38.6c-2.6-15.5-3.1-31.2-1.9-46.8c1.4-19.1,6.4-37.4,13.9-55.1c7-16.6,16.1-32,27.2-46.2
        c6.1-7.7,12.9-14.7,20-21.5c17.5-16.8,37.5-29.6,59.7-39.2c5-2.2,10-4.2,15-6.3C136.9,23.7,137.1,24,137.4,24.3z M141.4,334.5
        c5.4-0.4,9.3-2.7,12.7-5.7c3.6-3.2,7.1-6.6,10.2-10.3c10.3-12.5,18.7-26.2,26.3-40.4c10.9-20.4,20.1-41.5,28.1-63.2
        c7.2-19.6,13.6-39.4,18.7-59.6c3.6-14.1,6.5-28.4,9.3-42.6c1.4-7.3,2.4-14.7,2.9-22.2c0.5-8,0.5-16,0.1-24
        c-0.2-4.6-1.1-9.2-3.2-13.5c-3.7-7.2-9.2-9.3-16.4-5.6c-2.1,1-4.2,2.2-5.9,3.7c-4.1,3.9-8.3,7.8-11.8,12.1
        c-9.3,11.5-16.9,24.2-23.8,37.3c-12.1,23-22,46.8-30.8,71.2c-8,22.3-14.6,45-19.8,68c-1.5,6.8-2.9,13.6-4,20.4
        c-1,6-1.6,12.1-2.3,18.2c-0.7,6.4-1.5,12.9-1.7,19.3c-0.1,6,0.5,11.9,0.8,17.9c0.2,4.2,1.4,8.1,3,12
        C135.2,331.4,137.5,334,141.4,334.5z"/>
    </g>
  </svg>
)