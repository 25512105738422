import React from "react"
import navStyles from "./nav.module.css"

import Logo from './svg/logo'
import Rings from './svg/rings'
import Instagram from './svg/instagram'
import Phone from './svg/phone'

export default props => {
  const setIconClassName = id => id === props.currentScreen ? [navStyles.activeIcons, navStyles.footerIcons].join(' ') : [navStyles.inactiveIcons, navStyles.footerIcons].join(' ')

  const setLabelClassName = id => id === props.currentScreen ? [navStyles.activeLabels, navStyles.footerLabels].join(' ') : [navStyles.footerLabels, navStyles.inactiveIcons].join(' ')

  return ( 
    <nav>
      <div id={navStyles.iconWrapper}>
        <button id="welcome" className={navStyles.navBtns} onClick={props.handleIconClick}>
          <Logo iconClass={setIconClassName('welcome')} />
          <p className={setLabelClassName('welcome')}>Welcome</p>
        </button>
        <button id="weddings" className={navStyles.navBtns} onClick={props.handleIconClick}>
          <Rings iconClass={setIconClassName('weddings')} />
          <p className={setLabelClassName('weddings')}>Weddings</p>
        </button>
        <a id="follow" className={navStyles.navBtns} href="https://instagram.com/jvphotographerdesigner">
          <Instagram iconClass={[navStyles.footerIcons, navStyles.inactiveIcons].join(' ')} />
          <p className={navStyles.footerLabels}>Follow</p>
        </a>
        <button id="contact" className={navStyles.navBtns} onClick={props.handleIconClick}>
          <Phone iconClass={setIconClassName('contact')} />
          <p className={setLabelClassName('contact')}>Contact</p>
        </button>
      </div>
    </nav>
  )
}