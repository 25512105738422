import React from 'react'

export default props => (
  <svg className={props.iconClass} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 35 35">
    <g>
      <path d="M25.8,0h-15C9.5,0,8.5,1,8.5,2.3v30.3c0,1.3,1,2.4,2.3,2.4h15.1c1.3,0,2.3-1.1,2.3-2.4V2.3
        C28.1,1,27.1,0,25.8,0z M15.9,1.7h4.8C20.9,1.7,21,1.8,21,2s-0.1,0.3-0.3,0.3h-4.8c-0.2,0-0.3-0.1-0.3-0.3S15.7,1.7,15.9,1.7z
        M18.3,33.8c-0.6,0-1.1-0.5-1.1-1.2s0.5-1.2,1.1-1.2s1.1,0.5,1.1,1.2S18.9,33.8,18.3,33.8z M26.5,30.6H10.1V3.7h16.5v26.9H26.5z"/>
    </g>
  </svg>
)