import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import photoGridStyles from './photoGrid.module.css'

const imageSrcArr = []

const MappedImages = props => {  
    const getWeddingPhotos = useStaticQuery(graphql`
      query getWeddingPhotos {
        allFlamelinkPortfolioContent(filter: {work: {elemMatch: {category: {eq: "Wedding"}}}}) {
          nodes {
            work {
              photosDesign {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
  
  
  const gridImages = getWeddingPhotos.allFlamelinkPortfolioContent.nodes

  const mapWeddingImages = () => {
    gridImages.forEach(obj => obj.work[0].photosDesign.forEach((job, i) => {
      if (job.localFile) {
        const imageSrc = job.localFile.childImageSharp.fluid
        imageSrcArr.push(imageSrc)
      }
    }))

    return imageSrcArr.map((src, i) => (
      <div className={photoGridStyles.imageWrappers} onClick={props.handleImageClick} data-index={i} key={`image-wrapper-${i}`}> 
        <Img className={photoGridStyles.individualImages} fluid={src} imgStyle={{objectPosition: `center 25%`}} alt="wedding photo" />
      </div>
    )
  )}  
  
  return <>{mapWeddingImages()}</>
}

export default props => {
  const [expandedImageIndex, setExpandedImageIndex] = useState(0)
  
  const handleImageClick = e => {
    setExpandedImageIndex(e.currentTarget.dataset.index)
    props.toggleImage()
    console.log('expanded image index: ', expandedImageIndex)
  }

  const slideContainerStyles = {
    zIndex: `101`, 
    gridColumn: `1`,
    gridRow: `1/4`,
    backgroundColor: `var(--black)`,
    paddingTop: `75px`,
    paddingBottom: `50px`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    maxWidth: `960px`,
    width: `100%`,
    justifySelf: `center`,
  }

  return (
    <>
      {props.imageState && <button onClick={props.toggleImage} className={photoGridStyles.backButtons}>Back to All Photos</button>}
      {props.imageState &&
      <SwipeableViews style={slideContainerStyles} containerStyle={{height: `100%`}} index={expandedImageIndex} slideClassName={photoGridStyles.expandedImages} resistance={true} enableMouseEvents> 
        {imageSrcArr.map((src, i) => <Img fluid={src} key={`pic-${i}`} alt="wedding photo" imgStyle={{right: 0, margin: `auto`, width: `unset` }} draggable={false} />)}
      </SwipeableViews>}
    <div className={photoGridStyles.gridWrapper}>
      <MappedImages imagesToRender="grid-images" gridCategory={props.category} handleImageClick={handleImageClick} />
    </div>
    <div className="bottom-padding"></div>
    </>
  )
}
