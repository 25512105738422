import React from 'react'

import contactStyles from './contact.module.css'
import cellphone from '../../images/icons/iPhone.svg'
import envelope from '../../images/icons/envelope.svg'
import Instagram from '../svg/instagram'

export default () => (
  <div className={contactStyles.contactWrapper}>
    <h1 className={contactStyles.headers}>Contact Me</h1>
    <p className={contactStyles.descriptions}>You can reach me via phone, email, or Instagram DM, so feel free to call or shoot me a message. I currently reside in Indianapolis, IN but love to travel and am available to shoot destination weddings.</p>
    <p className={contactStyles.descriptions}>Looking forward to hearing from you,</p>
    <p className={contactStyles.descriptions} style={{textAlign: `left`}}>Jonatan</p>
    <a className={contactStyles.contactMethodWrappers} href="mailto:jonatanvelazquez@gmail.com">
      <img className={contactStyles.icons} src={envelope} alt="email icon" />
      <p>jonatanvelazquez@gmail.com</p>
    </a>
    <a className={contactStyles.contactMethodWrappers} href="tel:1-317-460-1519‬">
      <img className={contactStyles.icons} src={cellphone} alt="email icon" />
      <p>(317) 460-1519</p>
    </a>
    <a className={contactStyles.contactMethodWrappers} href="https://instagram.com/jvphotographerdesigner">
      <Instagram iconClass={contactStyles.icons} />
      <p>@jvphotographerdesigner</p>
    </a>
  </div>
)