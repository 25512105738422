import React, { useState, useEffect } from 'react'

import NavBar from '../components/nav'
import Welcome from '../components/welcome/welcome'
import PhotoGrid from '../components/collections/photoGrid/photoGrid'
import Contact from '../components/contact/contact'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => {
  const [currentScreen, setCurrentScreen] = useState("welcome")
  const [isImageExpanded, setisImageExpanded] = useState(false)
  const [isHeaderFooterVisible, setHeaderFooterVisible] = useState(true)
  const [viewportHeightUnit, setViewportHeightUnit] = useState(0)
  const [viewportWidthUnit, setViewportWidthUnit] = useState(0)
  
  useEffect(() => {
    setViewportHeightUnit(Math.min(document.documentElement.clientHeight, window.innerHeight))
    setViewportWidthUnit(document.documentElement.clientWidth)

    document.body.style.height = `${viewportHeightUnit}px`  
    document.body.style.width = `${viewportWidthUnit}px`
    document.documentElement.style.setProperty('--vh', `${viewportHeightUnit}px`)  
  }, [viewportHeightUnit, viewportWidthUnit])


    
  useEffect(() => window.addEventListener('resize', () => {
    setViewportHeightUnit(Math.min(document.documentElement.clientHeight, window.innerHeight))
    setViewportWidthUnit(document.documentElement.clientWidth)
  }))

  const handleNavbarClick = e => {
    setCurrentScreen(e.currentTarget.id)
  }

  const toggleHeaderFooter = e => {
    e.preventDefault()
    setHeaderFooterVisible(!isHeaderFooterVisible)
  }

  const handleScreenState = () => (
    currentScreen === "welcome" ? <Welcome /> 
      : currentScreen === "weddings" ? <PhotoGrid toggleImage={toggleExpandedPhotoState}
        imageState={isImageExpanded} toggleHeaderFooter={toggleHeaderFooter} />
      : currentScreen === "contact" ? <Contact />  
      : null
  )

  const toggleExpandedPhotoState = () => {
    setisImageExpanded(!isImageExpanded)  
    setHeaderFooterVisible(true)
  }
  
  return (
    <>
      <Layout headerState={isHeaderFooterVisible}>
        <SEO title="Home" /> 
        {handleScreenState()}
      </Layout>
      {isHeaderFooterVisible && <NavBar handleIconClick={handleNavbarClick} currentScreen={currentScreen} />}
    </>
)}

export default IndexPage